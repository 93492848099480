/* eslint-disable react-hooks/exhaustive-deps, @typescript-eslint/no-unused-vars*/
import clsx from 'clsx';
import { FieldArray, Form, Formik, setNestedObjectValues, Field } from 'formik';
import { FC, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap-v5';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Notice from '../../../modules/common/components/Notice';
import { IProfileState, IStudentConfig } from '../models';
import Select from 'react-select';
import { getConfig } from '../services/StudentProfileService';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { PageTitle } from '../../../../theme/layout/core';
import { deleteEssayForStudent, getEssaysForStudent, StudentEssayRequestDto, StudentEssayResponseDto, upsertEssayForStudent } from '../services/EssayService';
import { AsyncDropdown } from '../../../modules/common/components/AsyncDropdown';
import { getAllUniversitiesApi } from '../../reports/ReportService';

type Props = {
    profileId: string,
}

const validationSchema = Yup.object().shape({
    header: Yup.string().required().label('Topic'),
    essay: Yup.string().required().label('essay'),
});

const newValidationSchema = Yup.object().shape({
    description: Yup.string().required("A Description is Required!"),
    questionsandanswers: Yup.array().of(
      Yup.object().shape({
        question: Yup.string().required("A Question is Required!"),
        answer: Yup.string().required("An Answer is Required!"),
        wordcount: Yup.number().required("A Word Count is Required"),
      })
    ).min(1, "At least one question and answer is required"),
    universityId: Yup.number().required("University Is Required"),
  });

const countWords = (text: string): number => {
    return text.trim().split(/\s+/).filter(word => word.length > 0).length;
  };

const StudentEssayPage: FC<RouteComponentProps<Props>> = ({ history, match: { params: { profileId } } }) => {
    const [loading, setLoading] = useState(false);
    const [conf, setConfig] = useState<IStudentConfig>();
    const essayDetail: StudentEssayResponseDto = {
        id: 0,
        profileId: +profileId,
        description: '',
        questionsandanswers: [{
            question: '',
            answer: '',
            wordcount: 500
        }],
        numberOfEssays: conf?.numberOfEssays,
        universityId: 0,
        createdAt: '',
        createdBy: '',
        updatedAt: '',
        updatedBy: '',
    }
    const [essays, setEssays] = useState<StudentEssayResponseDto[]>();
    const [university, setUniversity] = useState<string | null>(null);

    const [refreshKey, setRefreshKey] = useState(1);
    const [profileState, setProfileState] = useState<IProfileState>();
    const [hasPendingPayment, setHasPendingPayment] = useState<boolean>(false);
    interface University {
        id: number;
        name: string;
        district: string | null;
    }
    interface SelectUniversity {
        value: number;
        label: string;
    }
    const [universityOptions, setUniversityOptions] = useState<SelectUniversity[]>([]);
    useEffect(() => {
        fetchUniversities();
    },[])


    useEffect(() => {
        function fetchData() {
            setLoading(true);
            getConfig(+profileId)
                .then(resp => {
                    setConfig(resp.data);
                    setProfileState(resp.data.profileState);
                    if (resp.data.overridePaymentRule) {
                        setHasPendingPayment(false);
                    } else if (resp.data.balanceFees) {
                        setHasPendingPayment(true);
                    }
                })
                .catch(err => setHasPendingPayment(true))
                .finally(() => setLoading(false));
        }

        fetchData();
    }, [profileId])

    // useEffect(() => {
    //     async function fetchData() {
    //         setLoading(true);
    //         try {
    //             const { data } = await getEssaysForStudent(+profileId);
    //             setEssays(data);
    //         } finally {
    //             setLoading(false);
    //         }
    //     }

    //     fetchData();
    // }, [refreshKey]);

    const handleDelete = (essayId: number) => {
        if (essayId === 0) {
            // remove from local
            setRefreshKey(Math.random());
            return;
        }

        setLoading(true);
        deleteEssayForStudent(essayId)
            .then(() => {
                setRefreshKey(Math.random());
                toast('Answers deleted successfully', { type: 'success' })
            })
            .finally(() => setLoading(false));
    }

    const handleSubmit = (profileId: string, values: StudentEssayRequestDto) => {
        setLoading(true);
        let formData = new FormData();
        formData.append("profileId", values.profileId.toString());
        formData.append("id", values.id.toString());
        formData.append("universityId", values.universityId.toString());
        formData.append("description", values.description.toString());
        formData.append("questionsandanswers", values.questionsandanswers.toString());
        formData.append("numberOfEssays", values?.numberOfEssays?.toString() || "");
        console.log("Payload: " + JSON.stringify(values));
        // upsertEssayForStudent(formData)
        //     .then(() => {
        //         setRefreshKey(Math.random());
        //         toast('Changes saved successfully', { type: 'success' })
        //     })
        //     .finally(() => setLoading(false));
        setLoading(false);
    }

    const fetchUniversities = async () => {
        try
        {
            const res = await getAllUniversitiesApi();
            const formattedOptions = res.data.map((university: University) => ({
                label: university.name,
                value: university.id
            }));
            setUniversityOptions(formattedOptions);
        } catch (err) {
            console.error('Error Fetching Universities', err)
        }
    }

    if (!loading && profileState && !profileState.isApproved) {
        return <Notice message='This module is not yet active as your profile is not approved.' />
    }
    
    // if (!loading && (hasPendingPayment)) {
    //     return <Notice message='This module is not yet active due to pending payment.' />
    // }

    return <>
        <PageTitle description='Manage Essays' breadcrumbs={[]}>Essays</PageTitle>
        {
            !loading && !profileId ? <Notice message='Profile not found' /> :
                <div className='card mb-5 mb-xl-10'>
                    <div className="card-header">
                        <h3 className='card-title mb-2'>
                            Essays
                        </h3>
                        <Button className='btn btn-sm btn-outline' variant='outline'>
                            <Link to={`/student/${profileId}/essays`}>
                                <i className='bi bi-view-list'></i> Preview
                            </Link>
                        </Button>
                    </div>
                    <div className='card-body pt-9 pb-0'>
                        <div className='row mb-10'>
                            {!conf?.requiresEssay && <div className='col-xl-6'>
                                <Notice message='Profile does not require Essay.' />
                            </div>
                            }
                            {loading && <Spinner variant='primary' animation='grow' />}

                            {/* {sharedState.isStudent && profileState && profileState.isSopQuestionnaireApproved && <>
                                <div className='col-xl-12'>
                                    <Notice message='ESSAY has been approved.' />
                                </div>
                            </>} */}

                            {conf?.requiresEssay && !loading &&
                            // {!loading &&
                                <>
                                    <Button className='btn btn-sm btn-primary col-2' onClick={() => {
                                        if (conf?.numberOfEssays && essays?.length && conf?.numberOfEssays <= essays.length) {
                                            toast(`Max ${conf.numberOfEssays} essays`, { type: 'error' });
                                            return;
                                        }
                                        setEssays(() => [...(essays || []), { description: '', questionsandanswers: [{question: '', answer: '', wordcount: 0}], universityId: 0, createdAt: '', createdBy: "", updatedBy: '', updatedAt: '', id: 0, profileId: +profileId, numberOfEssays: conf?.numberOfEssays}])
                                    }}>Add Another</Button>

                                    {essays &&
                                        essays.map(essayDetail => {
                                            return <div className='d-flex flex-row-fluid flex-center bg-white rounded' key={`selectedLor_${essayDetail.id}`}>
                                                <Formik initialValues={essayDetail} onSubmit={(values) => {
                                                    handleSubmit(profileId, values);
                                                }} validationSchema={newValidationSchema}>
                                                    {({ ...formik }) => (
                                                        <Form className='row w-60'>
                                                            <div className='col-xl-12 mb-10'>
                                                                {/* <label className='form-label fw-bolder text-dark fs-6 required'>{formik.values.header || 'Essay Topic'}</label>
                                                                <input
                                                                    placeholder='Essay Topic'
                                                                    autoComplete='off'
                                                                    {...formik.getFieldProps(`header`)}
                                                                    className={clsx(
                                                                        'form-control form-control-sm ',
                                                                        {
                                                                            'is-invalid': formik.touched.header && formik.errors.header,
                                                                        },
                                                                        {
                                                                            'is-valid': formik.touched.header && !formik.errors.header,
                                                                        }
                                                                    )}
                                                                />
                                                                {formik.touched.header && formik.errors.header && (
                                                                    <div className='fv-plugins-message-container'>
                                                                        <div className='fv-help-block text-danger mt-2'>
                                                                            <span role='alert'>Answer is Required</span>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <div className="col-xl-10 my-5">
                                                                    <label className='form-label fw-bolder text-dark fs-6 required'>{'Essay Content'}</label>
                                                                    <textarea
                                                                        placeholder='Essay Content'
                                                                        autoComplete='off'
                                                                        rows={20}
                                                                        {...formik.getFieldProps(`essay`)}
                                                                        className={clsx(
                                                                            'form-control form-control-sm ',
                                                                            {
                                                                                'is-invalid': formik.touched.essay && formik.errors.essay,
                                                                            },
                                                                            {
                                                                                'is-valid': formik.touched.essay && !formik.errors.essay,
                                                                            }
                                                                        )} />
                                                                    {formik.touched.essay && formik.errors.essay && (
                                                                        <div className='fv-plugins-message-container'>
                                                                            <div className='fv-help-block text-danger mt-2'>
                                                                                <span role='alert'>Answer is Required</span>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div> */}
                                                                <label className='form-label fw-bolder text-dark fs-6 required'>{university || 'University Name'}</label>
                                                                <Select
                                                                    options={universityOptions}
                                                                    onChange={(option) => {
                                                                        setUniversity(option ? option?.label : "Select a University");
                                                                        formik.setFieldValue('universityId', option?.value ?? 0);
                                                                    }}
                                                                    value={universityOptions.find(option => option.value === formik.values.universityId)}
                                                                    className={clsx(
                                                                        'form-control-sm',
                                                                        {
                                                                            'is-invalid': formik.touched.universityId && formik.errors.universityId,
                                                                        },
                                                                        {
                                                                            'is-valid': formik.touched.universityId && !formik.errors.universityId,
                                                                        }
                                                                    )}
                                                                />
                                                                <label className='form-label fw-bolder text-dark fs-6 required'>{formik.values.description || 'Essay Description'}</label>
                                                                <input
                                                                    placeholder='Essay Topic'
                                                                    autoComplete='off'
                                                                    {...formik.getFieldProps(`description`)}
                                                                    className={clsx(
                                                                        'form-control form-control-sm ',
                                                                        {
                                                                            'is-invalid': formik.touched.description && formik.errors.description,
                                                                        },
                                                                        {
                                                                            'is-valid': formik.touched.description && !formik.errors.description,
                                                                        }
                                                                    )}
                                                                />
                                                                {formik.touched.description && formik.errors.description && (
                                                                    <div className='fv-plugins-message-container'>
                                                                        <div className='fv-help-block text-danger mt-2'>
                                                                            <span role='alert'>Answer is Required</span>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <div className='col-xl-10 my-5'>
                                                                <FieldArray name="questionsandanswers">
                                                                {({ push, remove }) => (
                                                                    <div className="space-y-4 mt-4">
                                                                        {formik.values.questionsandanswers.map((_, index) => (
                                                                            <div key={index} className="border p-4 mb-4">
                                                                                <div className="mb-3">
                                                                                    <label className='form-label fw-bolder text-dark fs-6 required'>
                                                                                        Question {index + 1}
                                                                                    </label>
                                                                                    <Field
                                                                                        name={`questionsandanswers.${index}.question`}
                                                                                        className="form-control form-control-sm"
                                                                                        placeholder="Enter question"
                                                                                    />
                                                                                </div>
                                                                                <div className="mb-3">
                                                                                    <label className='form-label fw-bolder text-dark fs-6 required'>
                                                                                        Answer Word Count
                                                                                    </label>
                                                                                    <Field
                                                                                        name={`questionsandanswers.${index}.wordcount`}
                                                                                        className="form-control form-control-sm"
                                                                                        placeholder="Enter Word Count"
                                                                                        type="number"
                                                                                    />
                                                                                </div>
                                                                                <div className="mb-3">
                                                                                    <label className='form-label fw-bolder text-dark fs-6 required'>
                                                                                        Answer {index + 1}
                                                                                    </label>
                                                                                    <Field name={`questionsandanswers.${index}.answer`}>
                                                                                        {({ field }: any) => (
                                                                                            <div>
                                                                                                <textarea
                                                                                                    {...field}
                                                                                                    className="form-control form-control-sm mb-2"
                                                                                                    placeholder="Enter answer"
                                                                                                    rows={4}
                                                                                                />
                                                                                                <div className="d-flex justify-content-between">
                                                                                                    <small className={`text-${countWords(field.value) > (formik.values.questionsandanswers[index].wordcount || 0) ? 'danger' : 'muted'}`}>
                                                                                                        Words: {countWords(field.value)} / {formik.values.questionsandanswers[index].wordcount || 0}
                                                                                                    </small>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </Field>
                                                                                </div>
                                                                                {formik.values.questionsandanswers.length > 1 && (
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-sm btn-danger"
                                                                                        onClick={() => remove(index)}
                                                                                    >
                                                                                        Remove
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-sm btn-secondary"
                                                                            onClick={() => push({ question: '', answer: '', wordCount: 300 })}
                                                                        >
                                                                            Add Question
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </FieldArray>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex pt-10'>
                                                                <div className='m-2'>
                                                                    <button
                                                                        type='button'
                                                                        className='btn btn-md btn-primary w-100 mb-5'
                                                                        onClick={async () => {
                                                                            const validationErrors = await formik.validateForm()
                                                                            if (Object.keys(validationErrors).length > 0) {
                                                                                formik.setTouched(setNestedObjectValues(validationErrors, true));
                                                                                return;
                                                                            }
                                                                            formik.submitForm();
                                                                        }}
                                                                    >
                                                                        <i className="bi bi-box-arrow-down"></i>
                                                                        {!loading && 'Save'}
                                                                        {loading && (
                                                                            <span className='indicator-progress' style={{ display: 'inline-block' }}>
                                                                                Please wait...{' '}
                                                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                            </span>
                                                                        )}

                                                                    </button>
                                                                </div>
                                                                <div className='m-2'>
                                                                    <button
                                                                        type='button'
                                                                        className='btn btn-md btn-secondary me-3'
                                                                        onClick={() => {
                                                                            history.push(`/student/${profileId}/essay/submit`);
                                                                        }}
                                                                    >
                                                                        <i className="bi bi-hand-thumbs-up"></i>

                                                                        Preview and Submit
                                                                    </button>
                                                                    <button
                                                                        type='button'
                                                                        className='btn btn-md btn-secondary me-3'
                                                                        onClick={() => {
                                                                            handleDelete(essayDetail.id)
                                                                        }}
                                                                    >
                                                                        <i className="bi bi-hand-thumbs-up"></i>
                                                                        Remove
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </Form>)}
                                                </Formik>
                                            </div>
                                        })
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>}
    </>;
}

export default withRouter(StudentEssayPage);
