import axios from 'axios'

export interface QuestionAnswer {
  question: string;
  answer: string;
  wordcount: number;
}

export interface StudentEssayRequestDto {
  id: number
  description: string
  profileId: number
  universityId: number
  questionsandanswers: QuestionAnswer[]
  numberOfEssays?: number
}

export interface StudentEssayResponseDto extends StudentEssayRequestDto {
  createdBy: string
  createdAt: string
  updatedBy: string
  updatedAt: string | null
}

const API_URL = process.env.REACT_APP_API_URL
export const ESSAY_API = `${API_URL}/api/v1/student-essay`

export const getEssaysForStudent = (profileId: number) => {
  return axios.get<Array<StudentEssayResponseDto>>(`${ESSAY_API}/?profileId=${profileId}`)
}

// export const getEssaysForStudentNew = (profileId: number) => {
//   return axios.get<Array<StudentEssayResponseNewDto>>(`${ESSAY_API}/?profileId=${profileId}`)
// }

export const upsertEssayForStudent = (req: StudentEssayRequestDto) => {
  return axios.put<StudentEssayRequestDto>(`${ESSAY_API}`, req)
}

export const deleteEssayForStudent = (reqId: number) => {
  return axios.delete(`${ESSAY_API}/${reqId}`)
}

export const submitEssays = (profileId: number) => {
  return axios.post(`${ESSAY_API}/${profileId}/submit`)
}